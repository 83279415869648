import React, { useState, useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'

import AppSidebarTop from './sidebar/app-sidebar-top';
import AppSidebarBottom from './sidebar/app-sidebar-bottom';
import AppContentTop from './content/app-content-top';
import AppContentBottom from './content/app-content-bottom';

import './themes/default.css';
import './App.css';

Amplify.addPluggable(new AWSIoTProvider({
	aws_pubsub_region: 'us-east-1',
	aws_pubsub_endpoint: 'wss://amr9841oqoemt-ats.iot.us-east-1.amazonaws.com/mqtt',
}))

function App() {

	const [ appProperties, setAppProperties ] = useState();

	const toggleSidebar = () => {

		setAppProperties( {
			...appProperties,
			'sidebarOpen' : !appProperties.sidebarOpen ? true : false
		} )

	}

	const getUser = async () => {

		const currentUser = await Amplify.Auth.currentAuthenticatedUser();

		setAppProperties( {
			'user'			 	 	:  currentUser.signInUserSession.accessToken.payload,
			'selectedSection'	 	: 'Home',
			'selectedSubSection' 	: '',
			'selectedSubSectionItem': '',
			'sidebarOpen'	 	 	: false,
			'isMonkeyNet'			: false
		} );

	};

	const signOut = async () => {

		try {
			await Amplify.Auth.signOut()
			window.location.reload()
		} catch (error) {
			console.log('error signing out: ', error);
		}

	}

	const setAppProperty = ( property, value ) => {

		if ( typeof property === "object" )
			setAppProperties( property )

		if ( typeof property !== "object" )
			if ( appProperties.hasOwnProperty( property ) && property !== 'user' )
				setAppProperties( { ...appProperties, [property] : value } )

	}

	const setSelectedSection = ( selection ) => {

		if ( selection === 'signout' ) {
			signOut()
		} else {
			setAppProperty( 'selectedSection', selection )
		}

	}

	const canViewSite = () => {

		if (
				appProperties
			&& appProperties.hasOwnProperty('user')
			&& appProperties.user.hasOwnProperty('cognito:groups')
			&& appProperties.user['cognito:groups'].length > 0
		)
			return true

		return false

	}

	useEffect( () => {

		if ( !appProperties || !appProperties.hasOwnProperty('user') ) {
			getUser();
		};

	});

	if ( canViewSite() )
		return (
			<div className="App">
				<div className="App-sidebar" data-state-open={ appProperties.sidebarOpen }>
					<AppSidebarTop appProperties = { appProperties } toggleSidebar = { toggleSidebar } />
					<AppSidebarBottom appProperties = { appProperties } setAppProperty = { setAppProperty } />
				</div>
				<div className="App-content" data-state-open	= { appProperties.sidebarOpen }>
					<AppContentTop
						appProperties = { appProperties }
						toggleSidebar = { toggleSidebar }
						setSelectedSection = { setSelectedSection }
					/>
					<AppContentBottom
						appProperties 	= { appProperties }
						setAppProperty 	= { setAppProperty }
					/>
				</div>
			</div>
		);

	return (
		<span />
	)

}

export default withAuthenticator( App );
