import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Formcontrol from 'react-bootstrap/FormControl'
import FormCheck from 'react-bootstrap/FormCheck'

export default function SubSectionEdit ( { editMode, setEditMode, boardObject, saveBoardObject } ) {

	const [ editBoardObject, setEditBoardObject ] = useState( boardObject );
	const [ show, setShow ] = useState( false )

	const onChangeName = ( event ) => {
		setEditBoardObject( { ...editBoardObject, 'name' : event.target.value } )
	}

	const onChangePrivate = ( event ) => {
		setEditBoardObject( { ...editBoardObject, 'private' : event.target.checked } )
	}

	const onChangeDelete = ( event ) => {
		setEditBoardObject( { ...editBoardObject, 'flag1' : event.target.checked } )
	}

	const handleClose = () => setEditMode('inactive');

	const handleSave = () => {
		if ( saveBoardObject )
			saveBoardObject( editBoardObject )
		handleClose()
	}

	const isPrivate = () => {
		if (
			editBoardObject
			&& editBoardObject.hasOwnProperty('private')
		)
			return editBoardObject.private

		return false
	}

	const boardName = () => {
		if (
			editBoardObject
			&& editBoardObject.hasOwnProperty('name')
		)
			return editBoardObject.name

		return ''

	}

	const handleKeyPress = ( event ) => {

		if ( event.key === "Enter" )
			handleSave()

	}

	const showEdit = () => {
		let val = editMode === 'New' || editMode === 'Edit' ? true : false
		if ( val !== show ) {
			if ( val )
				setEditBoardObject( boardObject )
			setShow( val )
		}
	}

	showEdit()

	if ( show )
		return (
			<div>
				<div className = "App-sidebar-subsection-form">
					<Formcontrol
						placeholder = "Name"
						type 		= "text"
						size 		= "sm"
						value 		= { boardName() }
						onChange 	= { onChangeName }
						onKeyPress	= { handleKeyPress }
						autoFocus
					/>
					<FormCheck
						id		 = "custom-private-switch"
						type	 = "switch"
						checked	 = { isPrivate() }
						label	 = "Private"
						onChange = { onChangePrivate }
					/>
					{ editMode === 'Edit' && (
						<FormCheck
							id		 = "custom-delete-switch"
							type 	 = "switch"
							label	 = "Delete"
							onChange = { onChangeDelete }
						/>
					)}
				</div>
				<div className = "App-sidebar-subsection-form-buttons">
					<Button variant="light" onClick={handleClose}>
						Cancel
					</Button>
					<Button variant="light" onClick={handleSave}>
						Save
					</Button>
				</div>
			</div>
		)

	return null

}
