
export const boardsSubSections = {
	Lists 	: {
		type: 100
	},
	Notes 	: {
		type: 200
	},
	Recipes : {
		type: 300
	},
	Tasks	: {
		type: 400
	}
};


