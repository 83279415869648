/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBasicItem = /* GraphQL */ `
  mutation CreateBasicItem(
    $input: CreateBasicItemInput!
    $condition: ModelBasicItemConditionInput
  ) {
    createBasicItem(input: $input, condition: $condition) {
      id
      parentId
      createdAt
      updatedAt
      type
      sort
      private
      flag1
      flag2
      user
      name
      description
      data
    }
  }
`;
export const updateBasicItem = /* GraphQL */ `
  mutation UpdateBasicItem(
    $input: UpdateBasicItemInput!
    $condition: ModelBasicItemConditionInput
  ) {
    updateBasicItem(input: $input, condition: $condition) {
      id
      parentId
      createdAt
      updatedAt
      type
      sort
      private
      flag1
      flag2
      user
      name
      description
      data
    }
  }
`;
export const deleteBasicItem = /* GraphQL */ `
  mutation DeleteBasicItem(
    $input: DeleteBasicItemInput!
    $condition: ModelBasicItemConditionInput
  ) {
    deleteBasicItem(input: $input, condition: $condition) {
      id
      parentId
      createdAt
      updatedAt
      type
      sort
      private
      flag1
      flag2
      user
      name
      description
      data
    }
  }
`;
