/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e2559ab3-9520-4c67-8278-70876b102def",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pVmPMuxzu",
    "aws_user_pools_web_client_id": "7l6pctephkfi81iuai3r1l97k2",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://zuetnc4zdnbqvc6okydziqsb7i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-yvehxinnlvc5zfishe77yxdd5q",
    "aws_cloud_logic_custom": [
        {
            "name": "rootvault224mqqtdata",
            "endpoint": "https://aazsm5dx63.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "rootvault224mqqtdata-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
