import React from 'react'
import BoardsSidebar from './boards'
import HomeSidebar from './home'
import SettingsSidebar from './settings'


const SideBars = {
	Home	: HomeSidebar,
	Boards 	: BoardsSidebar,
	Settings: SettingsSidebar
}


export default function AppSidebarBottom( { appProperties, setAppProperty } ) {

	if (
			appProperties
		&&  appProperties.selectedSection
		&&  SideBars[ appProperties.selectedSection ]
	) {

		const Content = SideBars[ appProperties.selectedSection ]

		return (
				<div className="App-sidebar-bottom">
					<Content appProperties = { appProperties } setAppProperty = { setAppProperty } />
				</div>
			)

	}

	return ( <div className="App-sidebar-bottom" /> )

}
