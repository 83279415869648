import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import SVG from '../common/svg'

export default function AppSidebarTop ( { appProperties, toggleSidebar } ) {


	const Brand = () => {

		if ( appProperties.sidebarOpen )
			return <Navbar.Brand>{ appProperties.selectedSection }</Navbar.Brand>

		return  <span/>

	}

	return (
		<div className="App-sidebar-top">
			<Navbar>
				<Nav
					 onClick={ toggleSidebar }
				>
					<Nav.Link>
						<div className="top-nav-svg" >
							<SVG pathName = "hamburger" />
						</div>
					</Nav.Link>
					<Brand />
				</Nav>
			</Navbar>
		</div>
	)

}
