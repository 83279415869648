import React, { Suspense } from 'react'

const LazyContent = {
	Home	: React.lazy( () => import( './home' ) ),
	Boards	: React.lazy( () => import( './boards/' ) ),
	Settings: React.lazy( () => import( './settings' ) ),
}

const contentOverflow = {
	Home	: true,
	Board	: false,
	Settings: false
}

export default function ContentRouter ( { appProperties, setAppProperty } ) {

	if ( appProperties && appProperties.selectedSection ) {

		const Content = LazyContent[ appProperties.selectedSection ]
		return (
			<Suspense fallback = { <div/> } >
				<div
					className			= "App-content-bottom"
					data-overflow-auto 	= { contentOverflow[ appProperties.selectedSection ] }
				>
					<Content
						appProperties 	= { appProperties }
						setAppProperty	= { setAppProperty }
					/>
				</div>
			</Suspense>
		)

	}

	return ( <div></div> )

}
