import React from 'react'
import SVG from '../../common/svg'

export default function SubSectionHeader ( { appProperties, editMode, onBackButton, onAddButton } ) {

	return (
		<div className="App-sidebar-subsection-header">
			<div title="Back to list of Board types" onClick={ onBackButton } >
				<SVG pathName = "backArrow" />
			</div>
			<div title="Back to list of Board types" onClick={ onBackButton }>
				{appProperties.selectedSubSection}
			</div>
			{ editMode === "inactive" && (
			<div
				onClick={ onAddButton }
				title={ "Create new " + appProperties.selectedSubSection + " Board" }
			>
				<SVG pathName = "plusSign" />
			</div>
			)}
			{ editMode !== "inactive" && (
			<div/>
			)}
		</div>
	)

}
