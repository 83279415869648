/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBasicItem = /* GraphQL */ `
  query GetBasicItem($id: ID!) {
    getBasicItem(id: $id) {
      id
      parentId
      createdAt
      updatedAt
      type
      sort
      private
      flag1
      flag2
      user
      name
      description
      data
    }
  }
`;
export const listBasicItems = /* GraphQL */ `
  query ListBasicItems(
    $filter: ModelBasicItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBasicItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        createdAt
        updatedAt
        type
        sort
        private
        flag1
        flag2
        user
        name
        description
        data
      }
      nextToken
    }
  }
`;
export const itemBySort = /* GraphQL */ `
  query ItemBySort(
    $type: Int
    $sort: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBasicItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemBySort(
      type: $type
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        createdAt
        updatedAt
        type
        sort
        private
        flag1
        flag2
        user
        name
        description
        data
      }
      nextToken
    }
  }
`;
