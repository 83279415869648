import React,{ useEffect } from 'react'
import { boardsSubSections } from './types'

const groupsProperty = 'cognito:groups'

export const isMemberOf = ( appProperties, targetGroup ) => {

	let result = false

	if (
			appProperties
		&& 	appProperties.hasOwnProperty('user')
		&& 	appProperties.user.hasOwnProperty(groupsProperty)
	)
		appProperties.user[ groupsProperty ].forEach( group => {
			if ( group === targetGroup || group === 'administrator' )
				result = true
		})

	return result

}

export const getBoardsSubSectionType = ( appProperties ) => {


	if (
			appProperties.selectedSubSectionItem
		&& 	appProperties.selectedSubSectionItem.hasOwnProperty('type')
	)
		return Object.keys(boardsSubSections).map( subSection  => {
			if (
					boardsSubSections[subSection].hasOwnProperty('type')
				&&  boardsSubSections[subSection].type === appProperties.selectedSubSectionItem.type
			)
				return { ...boardsSubSections[subSection], name: subSection }
			return null
		}).filter( el => { return el != null } )[0]

	return {}

}


export const NavCustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
  	className="nav-link"
  	href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export function useOutsideAlerter(ref, callBack) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
				if ( callBack )
					callBack()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callBack]);
}

export function testForMonkeyNet( callback ) {

		fetch( 'http://pi05.monkeynet.net/api/isLocal.php' ).then(
			response => {
				if ( !response.ok ) {
					callback(false)
				} else {
					callback(true)
				}
			}
		).catch(
			err => {
				callback(false)
			}
		)

}

