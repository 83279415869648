import React from 'react'
import SubSection from './subsection'
import { boardsSubSections } from '../../common/types'


export default function BoardsSidebar( { appProperties, setAppProperty } ) {

	const SubSections = () => {
		return Object.keys(boardsSubSections).map( ( _key, key ) => {
			return <li key={key} data-key={_key}>{_key}</li>
		})
	}

	const selectSubSection = ( event ) => {
		setAppProperty( 'selectedSubSection', event.target.dataset.key )
	}

	if (
			appProperties.selectedSubSection
		&&	boardsSubSections.hasOwnProperty( appProperties.selectedSubSection )
	)
		return (
			<SubSection appProperties={ appProperties } setAppProperty={ setAppProperty } />
		)


	return (
		<ul className="App-sidebar-ul-main" onClick={selectSubSection}>
			<SubSections />
		</ul>
	)

}
