import { API, graphqlOperation } from 'aws-amplify';
import { itemBySort } from '../graphql/queries';
import {
	createBasicItem as createBasicItemMutation,
	updateBasicItem as updateBasicItemMutation,
	deleteBasicItem as deleteBasicItemMutation
} from '../graphql/mutations';
import { boardsSubSections } from '../common/types'

const queryActions = {
	create: createBasicItemMutation,
	update: updateBasicItemMutation,
	delete: deleteBasicItemMutation
}

export const getSubSectionType = ( subSection ) => {

	if ( boardsSubSections.hasOwnProperty( subSection ) )
		return boardsSubSections[ subSection ].type

	return 0

}

//	TODO:
//		 Change this to work like fetchSubSectionItemData
export async function basicItemAction( action, object, callBack ) {

	try {

		await API.graphql( { query: queryActions[action], variables: { input: object } } )

		if ( callBack )
			callBack( true )

	} catch ( err ) {

		return err

	}

	return 1

}

export async function fetchSubSectionItems( subSection, username, callBack ) {

	const apiData = await API.graphql(
		graphqlOperation( itemBySort, {
			type: getSubSectionType( subSection ),
			filter: {
				"or": [
					{ private: { eq: false } },
					{ user: { eq: username } }
				]
			}
		} )
	)

	callBack( apiData.data.itemBySort.items.sort(
		(a, b) => {
				var txtA = a.name.toUpperCase();
				var txtB = b.name.toUpperCase();
				return (txtA < txtB) ? -1 : (txtA > txtB) ? 1 : 0;
			}
		)
	)

}

export function fetchSubSectionItemData( _type, _id, callBack ) {

	const fetchRecord = async ( ) => {

		try {

			const apiData = await API.graphql(
				graphqlOperation( itemBySort,
						{
							type: _type + 1,
							filter: {
								parentId: { eq: _id }
						}
					}
				)
			)

			return apiData.data.itemBySort.items[0]

		} catch ( err ) {

			return err

		}

	}

	try {

		fetchRecord().then(
			result => {

				if ( typeof result === "undefined" ) {

					basicItemAction( "create",
						{
							type	: _type + 1,
							parentId: _id,
							sort	: 1,
							private : false,
							user	: "system",
							data	: "[]"
						}
					).then( () => {

						fetchRecord().then(
							result => {
								callBack( result )
							}
						)

					})

				} else {

					callBack( result )

				}

			}
		)

	} catch ( err ) {

		callBack( err )

	}

}

export function copySubSectionItemData( _type, _id, _item, callBack ) {

	const fetchDataRecord = async ( ) => {

		try {

			const apiData = await API.graphql(
				graphqlOperation( itemBySort,
						{
							type: _type + 1,
							filter: {
								parentId: { eq: _id }
						}
					}
				)
			)

			return apiData.data.itemBySort.items[0]

		} catch ( err ) {

			return err

		}

	}

	try {

		fetchDataRecord().then(
			result => {


				if ( typeof result !== "undefined" ) {

					let data = JSON.parse(result.data)

					if ( _item && _item.hasOwnProperty("id") ) {

						if ( data.findIndex( i => i.id === _item.id ) === -1 ) {

							data.push( _item )

							basicItemAction(
								"update",
								{
									id 	 : result.id,
									data : JSON.stringify( data )
								},
								null
							)

						}

					}

				}

			}
		)

	} catch ( err ) {

		if ( callBack ) {
			callBack( err )
		} else {
			console.log(2, err )
		}

	}


}


