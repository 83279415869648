import React, { useState } from 'react'
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap'
import { isMemberOf, NavCustomToggle } from '../common/utils'
import SVG from '../common/svg'

export default function AppContentTop( { appProperties, setSelectedSection, toggleSidebar } ) {

	const [ mainNavList, setMainNavList ] = useState([])

	const MainNavLinks = () => {
		return mainNavList.map( ( _link, key ) => {
			return <Dropdown.Item
						key 	 = { key }
						eventKey = { _link }
						active	 = { false }
					>
						{ _link }
					</Dropdown.Item>
		})
	}

	const Brand = () => {

		if ( appProperties.sidebarOpen )
			return  <span/>

		return <Navbar.Brand>{ appProperties.selectedSection }</Navbar.Brand>

	}

	const SidebarToggle = () => {

		if ( !appProperties.sidebarOpen )
			return (
				<Nav.Link>
					<div className="top-nav-svg" >
						<SVG pathName = "hamburger" />
					</div>
				</Nav.Link>
			)

		return null
	}

	if ( appProperties && mainNavList.length === 0 ) {

		let tmpList = []

		if ( isMemberOf( appProperties, 'general' ) ) {
			tmpList.push( 'Home' )
			tmpList.push( 'Boards' )
		}

		if ( tmpList.length > 0 )
			setMainNavList( tmpList )

	}

	return (
		<div className="App-content-top">
			<Navbar className="justify-content-between"	>
				<Nav onClick={ toggleSidebar }>
					<SidebarToggle />
					<Brand />
				</Nav>
				<Nav onSelect = { setSelectedSection } className="justify-content-end">
					<Dropdown alignRight={true}>
						<Dropdown.Toggle as={NavCustomToggle} id="dropdown-custom-components">
							<div className="top-nav-svg">
								<SVG pathName = "applications" />
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<MainNavLinks />
						</Dropdown.Menu>
					</Dropdown>
					<NavDropdown
						title		= { appProperties && appProperties.user ? appProperties.user.username : "unknown" }
						alignRight	= { true }
					>
						<NavDropdown.Item active={false} eventKey="Settings">Settings</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item eventKey="signout">Sign Out</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Navbar>
		</div>
	)

}



