import React, { useState, useEffect } from 'react'
import SubSectionHeader from './subsection-header'
import SubSectionEdit from './subsection-edit'
import {
	fetchSubSectionItems, basicItemAction,
	getSubSectionType, copySubSectionItemData
} from '../../common/graphql-basic-item'
import SVG from '../../common/svg'

export default function SubSection( { appProperties, setAppProperty } ) {

	const [ editMode, setEditMode ] = useState('inactive')
	const [ boardObject, setBoardObject ] = useState({})
	const [ items, setItems ] = useState([])
	const [ updateItems, setUpdateItems ] = useState(true)
	const [ subSection, setSubSection ] = useState('')

	const onBackButton = () => {
		setAppProperty( 'selectedSubSection', '' )
	}

	const onAddButton = () => {
		setBoardObject(
			{
				name	: '',
				private : false,
				sort	: 0,
				user	: appProperties.user.username,
				type	: getSubSectionType( subSection )
			}
		)
		setEditMode('New')
	}

	const onEditButton = ( item ) => {
		setBoardObject( item )
		setEditMode('Edit')
	}

	const saveBoardObject = ( object ) => {
		if ( editMode === 'New' )
			basicItemAction('create', object, setUpdateItems )
		if ( editMode === 'Edit' ) {
			if ( object.flag1 ) {
				basicItemAction( 'delete', { id: object.id	}, setUpdateItems )
			} else {
				basicItemAction('update', object, setUpdateItems )
			}
		}
	}

	const allowDrop = ( event ) => {
		event.preventDefault()
		event.target.setAttribute('data-drop-active','true')
	}

	const dragLeave = ( event ) => {
		event.preventDefault()
		event.target.setAttribute('data-drop-active','false')
	}

	const onDrop = ( event ) => {
		event.preventDefault()
		event.target.setAttribute('data-drop-active','false')
		copySubSectionItemData(
			getSubSectionType( subSection ),
			event.target.id,
			JSON.parse( event.dataTransfer.getData("json") ),
			null
		)
	}

	const onBoardClick = ( item ) => {

		setAppProperty(
			{
				...appProperties,
				'selectedSubSectionItem': item,
				'sidebarOpen' : false
			}
		)

	}

	const Boards = () => {
		return items.map( item => (
			<li
				key			 = { item.id }
				data-selected= {
					appProperties.selectedSubSectionItem && item.id === appProperties.selectedSubSectionItem.id ? true : false
				}
			>
				<div
					id			 = { item.id }
					onDrop 		 = { onDrop }
					onDragOver 	 = { allowDrop }
					onDragLeave  = { dragLeave }
					onClick 	 = { () => { onBoardClick( item ) } }
				>
					{item.name} { item.private && ( <div /> ) }
				</div>
				<div onClick={ ()=> { onEditButton( item ) } }><SVG pathName = "pencil" /></div>
			</li>
		))
	}

	useEffect( () => {
		if ( updateItems ) {
			var f = () => {
				setUpdateItems( false )
				fetchSubSectionItems(
					subSection,
					appProperties.user.username,
					setItems
				)
			}
			f()
		}
	})

	if ( subSection !== appProperties.selectedSubSection )
		setSubSection( appProperties.selectedSubSection )

	return (
		<>
			<SubSectionHeader
				appProperties = { appProperties }
				editMode = { editMode }
				onBackButton  = { onBackButton }
				onAddButton = { onAddButton }
			/>
			<SubSectionEdit
				editMode = { editMode }
				setEditMode = { setEditMode }
				boardObject = { boardObject }
				saveBoardObject = { saveBoardObject }
			/>
			{ editMode === "inactive" && (
			<ul className="App-sidebar-subsection-list">
				<Boards />
			</ul>
			)}
		</>
	)


}


